.token,
.homeimage {
  object-fit: cover;
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
}
model-viewer {
}
.iconimage {
  height: 40px;
}
.container {
  margin-top: 20px;
  margin-bottom: 80px;
}
.navbar-brand {
  margin: 5px;
}
.profile {
  height: 40px;
  width: 40px;
  border-radius: 50%;
}
td {
  white-space: normal !important;
  word-wrap: break-word;
}
table {
  table-layout: fixed;
}

.rounded {
  border-radius: 15px !important;
}

/* navbar-adj */
.navbar-adj {
  height: 80px;
}
/* nft-detail */
.nft-detail-adj .table-adj tr th,
.table-adj tr td {
  padding: 15px;
}
.nft-detail-adj .table-adj tr th {
  width: 20%;
  color: #fff;
}
.nft-detail-adj .table-adj tr {
  border: 1px solid #80808052;
}
.nft-detail-adj .table-adj {
}
.nft-detail-adj:hover .table-adj:hover {
  color: #fff;
}

table {
  border-collapse: separate;
  border: solid #333 1px !important;
  border-radius: 20px;
  -moz-border-radius: 20px;
}

thead:first-child tr:first-child th:first-child,
tbody:first-child tr:first-child td:first-child {
  border-radius: 15px 0 0 0;
}
thead:last-child tr:last-child th:first-child,
tbody:last-child tr:last-child td:first-child {
  border-radius: 0 0 0 15px;
}

th {
  border-top: none !important;
}

td:first-child,
th:first-child {
  border-left: none !important;
}

.homeButton {
  padding-left: 35px;
  padding-right: 35px;
  font-size: 25px;
  font-weight: 600;
  border-radius: 20px !important;
  outline: none;
  /* background: linear-gradient(to right, rgb(115, 76, 226), rgb(96, 107, 251)); */
  border: none;
  font-family: 'Exo 2', sans-serif !important;
  letter-spacing: 3px;
}

.homeButton:hover {
  color: #15dfc3;
  background-color: none;
  border-color: none;
  transform: scale(1.05);
  transition: 0.55s ease-in-out;
}
.homeButton3 {
  cursor: pointer !important;
  position: relative !important;
  display: inline-block !important;
  background: #20ebf0 !important;
  color: #12101b !important;
  font-weight: 700 !important;
  transition: border-color 0.1s linear 0s, background-color 0.1s linear 0s !important;
  border-color: transparent !important;
  padding: 0.5538rem 0.9231rem !important;
  min-width: 14.6154rem !important;
  text-transform: capitalize !important;
  box-shadow: none !important;
  letter-spacing: 3px !important;
}

.homeButton3::before {
  content: '';
  box-sizing: content-box;
  display: block;
  position: absolute;
  background-color: rgb(48 49 58);
  border-style: none;
  border-width: 2px;
  border-color: #fff;
  transition: transform 0.4s ease-in-out 0s, border-color 0.4s linear 0s,
    background-color 0.4s linear 0s;
  transform-origin: center center;
}

.homeButton3::before {
  top: -32px;
  left: -32px;
  width: 30px;
  height: 30px;
  overflow: visible !important;
  border-radius: 3px;
}

.homeButton3:hover {
  transform: scale(1) !important;
}
.homeButton3::after {
  content: '';
  box-sizing: content-box;
  display: block;
  position: absolute;
  border-width: 2px;
  border-color: #fff;
  transition: transform 0.4s ease-in-out 0s, border-color 0.4s linear 0s,
    background-color 0.4s linear 0s;
  transform-origin: center center;
}

.homeButton3::after {
  bottom: -32px;
  right: -32px;
  width: 30px;
  height: 30px;
  overflow: visible !important;
  border-radius: 3px;
}

.homeButton3::after {
  content: '';
  box-sizing: content-box;
  display: block;
  position: absolute;
  border-width: 2px;
  border-color: #fff;
  transition: transform 0.4s ease-in-out 0s, border-color 0.4s linear 0s,
    background-color 0.4s linear 0s;
  transform-origin: center center;
}

.homeButton3:hover::before {
  -webkit-transform: translateX(20px) rotate(-180deg);
  -moz-transform: translateX(20px) rotate(-180deg);
  -ms-transform: translateX(20px) rotate(-180deg);
  -o-transform: translateX(20px) rotate(-180deg);
  transform: translateX(20px) rotate(-180deg);
  color: #2d2d2d;
  background-color: #20ebf0;
  transition: transform 0.4s ease-in-out 0s, border-color 0.4s linear 0s,
    background-color 0.4s linear 0s;
  transform-origin: center center;
}

.homeButton3:hover::after {
  -webkit-transform: translateX(-20px) rotate(180deg);
  -moz-transform: translateX(-20px) rotate(180deg);
  -ms-transform: translateX(-20px) rotate(180deg);
  -o-transform: translateX(-20px) rotate(180deg);
  transform: translateX(-20px) rotate(180deg);
  color: #2d2d2d;
  background-color: #20ebf0;
  transition: transform 0.4s ease-in-out 0s, border-color 0.4s linear 0s,
    background-color 0.4s linear 0s;
  transform-origin: center center;
}

.actionGroup {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
  text-decoration: none;
}

.pointer {
  cursor: pointer;
}

.serabeBuy {
  text-align: center;
  width: 100%;
}

.serabeBordering {
  padding: 10px 0px;
  border-bottom: medium solid rgb(34, 234, 240);
  letter-spacing: 5px;
  color: rgb(52, 221, 231);
  border-top-width: medium;
  border-right-width: medium;
  border-left-width: medium;
}

.featuredContainer {
  display: flex;
  background: #21263e;
  margin: 0px auto;
  max-width: min(1280px, 100% - 40px);
  width: 100%;
  flex-wrap: wrap;
  border-radius: 15px;
}
.blockReact {
  width: 100%;
  padding: 110px 20px 44px 30px;
  flex-direction: column;
  -webkit-box-align: center;
  display: flex;
  text-align: center;
  justify-content: center;
  vertical-align: middle;
}

/* .left {
  align-items: flex-start;
}

.right {
  align-items: flex-end;
} */

h1.textReact {
  font-size: 45px;
  /* text-align: left; */
  max-width: 100%;
  margin-right: 10px;
  font-weight: 600;
  color: rgb(255, 255, 255);
  line-height: 110%;
}

.subheader {
  font-size: 24px;
  /* text-align: left; */
  margin-top: 20px;
  /* max-width: 400px; */
  color: rgb(138, 147, 155);
}

.blockPowered {
  height: 100%;
  align-items: flex-end;
  display: flex;
  font-size: 10px;
  color: white;
  margin-top: 40px;
  letter-spacing: 2px;
}
.poweredIconer {
  font-family: 'Exo 2', sans-serif !important;
  font-weight: 600;
}
svg.blue path {
  fill: #00bcd4;
}

.animImageWrapper {
  display: flex;
    flex-direction: column;
    width: 20%;
    min-width: 20%;
    padding-left: 20px;
}
.imageAnim {
  vertical-align: inherit;
  display: flex;
   -webkit-box-flex: 1;
  flex-grow: 1;
 flex-direction: column;
}
a.Imager1 {
  margin-bottom: 20px;
}
.stdoutImage {
  display: flex;
  -webkit-box-flex: 1;
  flex-grow: 1;
  flex-direction: column
}
/* home */
/* .home-adj .wrapper,.form-wrapper {border: 1px solid #8080802b;height: fit-content;box-shadow:0 0px 20px -3px #77777721}
.home-adj .heading-container h1,.form-wrapper h3 {padding: 0.3em 1em 0.3em 0.3em;background: aliceblue;font-size: 36px;} */
.home-adj,
.explore-adj,
.my-collect-adj,
.mint-token-adj,
.create-mint-adj,
.nft-detail-adj {
  padding: 3rem 1.5rem;
  border: 1px solid #21252914;
  margin-top: -20px;
  border-radius: 10px;
  box-shadow: rgb(5 6 13 / 24%) 7px 2px 26px 0px;
  background: rgb(33, 38, 62);
}

.home-adj a {
  color: #fff;
  text-decoration: none;
}

/* Animation Loading */
.show-loading-animation.rect-shape {
  background-color: rgb(21 26 47) !important;
}
/* explore-adj */
.explore-adj .card,
.my-collect-adj .card {
  box-shadow: 0 0px 20px -3px #00000077;
}
.explore-adj .card:hover,
.my-collect-adj .card:hover {
  box-shadow: 0 0px 25px -2px #6697ff77;
}
.explore-adj a,
.my-collect-adj a {
  text-decoration: none;
  color: #fff;
}

/* .token {width: 100%;} */

/* footer */
.footer-adj,
.navbar-adj {
  background-color: #0021423a !important;
  color: #fff;
}
.footer-adj p {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.max-250 {
  height: 250px;
  width: 100%;
  position: relative;
}

.max-150 {
  height: 150px;
  width: 100%;
  position: relative;
}

.max-300 {
  height: 300px;
  width: 100%;
}

.text-title {
  text-transform: uppercase;
  color: rgb(255 255 255);
  font-size: 14px;
  font-weight: 600;
  font-family: 'Exo 2', sans-serif !important;
  line-height: 1.5;
  padding: 2px 8px;
  background: #3050dc;
  width: fit-content;
  margin: 10px;
}
.head-title {
  display: inline-block;
  background: linear-gradient(to right, rgb(115, 76, 226), rgb(96, 107, 251));
  color: #fff;
  border-radius: 5px;
  padding: 5px 20px;
}

.intro {
  background: #00000096;
  text-align: center;
  z-index: 5000;
}

/* .intro:after {
  content:'';
  position: absolute;
  width: 0;
  height: 0;
  margin-top: 9px;
  border-left: 60px solid transparent;
  border-right: 60px solid transparent;
  border-top: 60px solid #00000096;
  left: 50%;
  margin-left: -20px;
} */

.wrapper {
  display: grid;
}
body {
  background-color: #151a2f;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  font-family: 'Exo 2', sans-serif;
}
.btn-primary {
  background-color: #1557b413;
  border-radius: 5px !important;
}
.buybtn {
  box-shadow: 0px 0px 10px #0054ca !important;
  border-radius: 15px !important;
  background-color: #1557b4b6 !important;
}
.eth-class {
  height: 26px;
  margin: 0px 5px;
  padding-bottom: 5px;
}
h2 {
  color: #fff;
}

.card {
  border-radius: 15px !important;
  background-color: #151a2f !important;
  color: #fff !important;
}

.table {
  color: #c0e0ff;
}

._loading_overlay_overlay {
  position: fixed;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
  appearance: textfield;
  margin: 0;
}

input[type='text'],
input[type='text']:focus,
input[type='file'],
input[type='file']:focus,
select[type='text'],
select[type='text']:focus,
textarea[type='text'],
textarea[type='text']:focus {
  background-color: rgb(21 26 47);
  color: #fff;
  border-radius: 5px;
  padding: 5px;
  border-color: #62a2bb;
  outline: none;
}

input[type='number'],
input[type='number']:focus,
input[type='file'],
input[type='file']:focus,
select[type='number'],
select[type='number']:focus,
textarea[type='number'],
textarea[type='number']:focus {
  background-color: #151a2f !important;
  color: #fff;
  border-radius: 5px;
  padding: 5px;
  border-color: #333;
  outline: none;
}

#fileupload {
  color: white;
  background-color: #222;
  border-color: #333;
  text-align: left;
  display: inline-block;
}

.nav-link {
  text-align: center;
}

.bg-dark {
  box-shadow: rgb(5 6 13 / 24%) 7px 2px 26px 0px;
  background-color: rgb(33, 38, 62) !important;
}

body::-webkit-scrollbar {
  width: 10px; /* width of the entire scrollbar */
}

body::-webkit-scrollbar-track {
  background: #333853; /* color of the tracking area */
}

body::-webkit-scrollbar-thumb {
  background-color: #21263e;
  border-radius: 2px;
}

.nav {
  justify-content: center;
}

.nav-tabs {
  border-bottom: 1px solid #313131;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #71b8ff !important;
  background-color: #000 !important;
  border-color: #313131 #313131 #313131 !important;
}

.nav-tabs .nav-link:hover {
  color: #71b8ff !important;
  background-color: #000 !important;
  border-color: #313131 #313131 #313131 !important;
}

.colltxt {
  font-size: 16px;
  color: #636363 !important;
}

.idbadge {
  margin-bottom: 5px;
  position: absolute;
  float: left;
  left: 0px;
  top: 15px;
  padding: 3px;
  padding-left: 10px;
  padding-right: 5px;
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
  color: #fff !important;
  background: linear-gradient(to right, rgb(115, 76, 226), rgb(96, 107, 251));
  font-size: 13px;
  box-shadow: 1px 2px 5px #00000059;
}

@media screen and (min-width: 601px) {
  .addy {
    font-size: 16px;
  }
  .container {
    max-width: 90% !important;
  }
}

/* If the screen size is 600px wide or less, set the font-size of <div> to 30px */
@media screen and (max-width: 600px) {
  .addy {
    font-size: 12px;
  }

  .mintbtn {
    margin-right: 0px !important;
  }
  .fouro {
    font-size: 158px !important;
  }

  .rainbowtxt1 {
    font-size: 33px !important;
  }

  .rainbowtxt2 {
    font-size: 33px !important;
  }

  .rainbowtxt3 {
    font-size: 33px !important;
  }

  .dropdown {
    margin-right: 0px !important;
  }
  .dropdown-menu {
    right: 0;
    left: auto;
    color: #fff;
    background-color: #151a2f;
    width: 100% !important;
    border-radius: 0px !important;
    padding: 30px !important;
  }
  #drop-area h3 {
    margin-top: -5px !important;
  }
  .container {
    max-width: 100% !important;
  }
}

.dropdown .bal {
  font-size: 19px;
  text-align: center;
}

svg {
  /* background: rgb(0, 0, 0);
  border: 2px solid white;
  border-radius: 50%; */
}

.mainfoot {
  background-color: #151a2f !important;
  min-height: 350px;
  width: 100%;
  padding: 20px;
}

.foottxt a {
  color: #fff;
  text-decoration: none;
  font-size: 15px;
  font-weight: bold;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 35px;
}

.avaimg {
  display: inline-block;
  
}

.dropdown-toggle::after {
  display: none !important;
}

.dropdown {
  margin-right: 30px;
  padding: 10px;
}

.dropdown-menu {
  color: #fff;
  background-color: #151a2f;
  width: 350px;
  border-radius: 10px;
  padding: 30px;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

.dropdown-item {
  color: #fff;
}

.dropdown-item:hover {
  color: #fff;
  background: linear-gradient(to right, rgb(115, 76, 226), rgb(96, 107, 251));
  border-radius: 5px;
}

#drop-area {
  border: 3px dashed #ccc;
  background-color: rgb(22, 22, 22) !important;
  border-radius: 20px;
  width: 90%;
  height: 200px;
  margin: 10px auto;
  padding: 20px;
}
#drop-area.highlight {
  border-color: rgb(2, 113, 218);
  background-color: #000 !important;
}
#drop-area h3 {
  margin-top: 25px;
}
p {
  margin-top: 0;
}
.my-form {
  margin-bottom: 10px;
}
#gallery {
  margin-top: 10px;
}
#gallery img {
  width: 150px;
  margin-bottom: 10px;
  margin-right: 10px;
  vertical-align: middle;
}
#fileElem {
  display: none;
}

model-viewer {
  --poster-color: #000;
}

.mintbtn {
  background: rgb(4, 15, 30);
  background: linear-gradient(to right, rgb(115, 76, 226), rgb(96, 107, 251));
  border-radius: 10px;
  border: none;
  padding: 15px;
  font-weight: 600;
  margin-top: 3px;
  margin-right: 15px;
  padding-left: 25px;
  padding-right: 25px;
  color: #fff;
  font-weight: bold;
}

.mintbtn:hover {
  color: #fff;
  transform: scale(1.05);
  transition: 0.5s;
}

.sale-design {
  position: relative;
}
.sale-design .sale-label {
  position: absolute;
  top: -9px;
  right: -10px;
  width: 82px;
  height: 15px;
  background: linear-gradient(to right, rgb(226 76 124), rgb(96, 107, 251));
  color: white;
  font-weight: 900;
  display: block;
  text-align: center;
  font-size: 11px;
  line-height: 15px;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.sale-design .sale-label:after {
  content: '';
  position: absolute;
  left: 10%;
  width: 0px;
  height: 0px;
  border-bottom: 5px solid transparent;
  border-left: 3px solid transparent;
  border-top: 7px solid #d34f8b;
  border-right: 5px solid transparent;
  top: 100%;
}
.swapbtn {
  background: linear-gradient(#29304c, #151a2f);
  border-radius: 10px;
  border: none;
  /* padding: 15px; */
  font-weight: 600;
  margin-top: 3px;
  padding-left: 25px;
  padding-right: 25px;
  color: #fff;
  font-weight: bold;
}

.blockButton {
  margin-top: 40px;
  display: flex;
}

.blockButton1 {
  display: block;
  margin-top: 40px;
}

.homeButton {
  margin-right: 20px !important;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 600;
  background-color: rgb(32, 129, 226);
  border: 1px solid rgb(53 65 114);
  color: rgb(255, 255, 255);
  text-decoration: none;
}

.swapbtn:hover {
  color: #fff;
  transform: scale(1.05);
  transition: 0.5s;
}

.like {
  color: #aaa;
}

.like:hover {
  color: #666;
}

.liked {
  color: #ff4343;
}

.ice {
  color: #aaa;
}

.ice:hover {
  color: #666;
}

.iced {
  color: #4992ff;
}

.mymodal {
  top: 50%;
  left: 50%;
  right: auto;
  background-color: #000;
  border-color: #333;
  border-radius: 15px;
  color: #fff;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}
#modalSerabe {
  border: 1px #040508 !important;
  box-shadow: rgb(115 75 226 / 51%) 0px 0.25rem 0.75rem 0px !important;
  background: linear-gradient(
    to right,
    rgb(226 76 201),
    rgb(20 186 213)
  ) !important;
}
.myoverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.85);
}

.btn-outline-light {
  margin-top: 6px;
  font-size: 18px;
  border: none;
  border-radius: 0.5625rem !important;
}

.nav-link {
  transition: color 1.15s ease-in-out, background-color 1.15s ease-in-out,
    border-color 1.15s ease-in-out !important;
}

.btn-outline-light:hover {
  color: #000;
  border-color: none !important;
  color: #fff !important;
  background: linear-gradient(
    to right,
    rgb(115, 76, 226),
    rgb(96, 107, 251)
  ) !important;
  box-shadow: rgb(115 75 226 / 51%) 0px 0.25rem 0.75rem 0px;
}

.categorynav {
  color: #fff !important;
  padding: 5px !important;
  border: 1px solid transparent;
  margin: 0px !important;
  font-size: 18px;
  margin-bottom: 5px !important;
  border-radius: 0.5625rem;
}

.categorynav:hover {
  color: #fff !important;
  background: linear-gradient(to right, rgb(115, 76, 226), rgb(96, 107, 251));
  box-shadow: rgb(115 75 226 / 51%) 0px 0.25rem 0.75rem 0px;
}

/* .container{
  display: flex;
  list-style: none;
  justify-content: center;
}

.page{
	padding: 10px;
    border: 1px solid #dcdcdc;
    border-radius: 6px;
    margin-right: 10px;
    cursor: pointer;
    color: #eee;
}

.disabled{
    cursor: not-allowed;
    
}

.active{
	border: 2px solid rgb(0, 132, 255);
  font-weight: bold;
  color: #fff;
}

.previous{
    padding: 10px;
    border-radius: 6px;
    margin-right: 10px;
    cursor: pointer;
}

.break{
    padding: 10px;
}

.next{
    padding: 10px;
    border-radius: 6px;
    margin-right: 10px;
    cursor: pointer;
} */

.checkbox {
  display: inline-flex;
  cursor: pointer;
  position: relative;
}

.checkbox > span {
  color: #34495e;
  padding: 0.5rem 0.25rem;
}

.checkbox {
  height: 21px;
  width: 21px;
  margin-top: 15px;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 1px solid #000;
  border-radius: 4px;
  outline: none;
  transition-duration: 0.3s;
  background-color: hsl(214, 69%, 49%);
  cursor: pointer;
}

.checkbox:checked {
  border: 1px solid hsl(214, 69%, 49%);
  background-color: #000000;
}

.checkbox:checked::before {
  content: '\2713';
  display: block;
  text-align: center;
  color: hsl(214, 69%, 49%);
  position: absolute;
  left: 0.2rem;
  top: -0.1rem;
}

.checkbox:active {
  border: 2px solid #34495e;
}

.rainbowtxt1 {
  font-size: 66px;
  color: #7db6e0;
  font-weight: bold;
}
.rainbowtxt2 {
  font-size: 66px;
  font-weight: bold;
  background: linear-gradient(to right, #7db6e0, #0b5ed7);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.rainbowtxt3 {
  font-size: 66px;
  font-weight: bold;
  background: linear-gradient(to right, #0b5ed7, #7db6e0);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.liquid {
  flex: 1;
  padding: 25px 50px;
  margin: 25px;
  border-radius: 999px;
  border: 5px solid $color;
  white-space: nowrap;
  color: #fff;
  background: transparent;
  background-image: url('nav-bg-fill-blue.png');
  background-repeat: repeat-x;
  background-position: 0 -200%;
  transition: 1.5s ease;
}

.liquid:hover {
  background-position: 500% 100%;
}

.liquid2 {
  flex: 1;
  padding: 15px 40px;
  margin: 25px;
  border-radius: 999px;
  border: 5px solid $color;
  white-space: nowrap;
  color: #fff;
  background: transparent;
  background-image: url('nav-bg-fill-blue.png');
  background-repeat: repeat-x;
  background-position: 0 -500%;
  transition: 1.5s ease;
}

.liquid2:hover {
  background-position: 500% 100%;
}

.Center {
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.dropdown-divider {
  border-top: 1px solid rgb(189 189 189 / 15%);
}

.watermark {
  position: relative;
}
.watermark::after {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  content: 'NFT PREVIEW';
  font-size: 2.5rem;
  font-family: 'Exo 2', sans-serif !important;
  font-weight: bold;
  color: rgb(0, 134, 211);
}

.mintinputs {
  padding: 15px !important;
}

.boxshadow {
  box-shadow: 0px 0px 15px 15px rgba(0, 0, 0, 0.45);
}

.card:hover {
  transform: scale(1.1);
  transition: 0.5s;
}

.fouro {
  font-size: 358px;
}

.mg16 {
  width: 22px;
  height: 22px;
  margin-right: 13px;
}
